html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.border {
  width: 100%;
  height: 100vh;
  background: hsl(0, 0%, 10%);
  text-align: center;
}

// Styling:
// https://css-tricks.com/old-timey-terminal-styling/
.tvBackdrop {
  width: 100%;
  height: 100vh;
  background-color: black;
  background-image: radial-gradient(
    // hsl(0, 0%, 30%), black 120%
    rgba(65, 87, 65, 0.75), black 120%
  );
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: repeating-linear-gradient(
      0deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 2px,
      transparent 2px,
      transparent 4px
    );
    pointer-events: none;
  }
}

canvas {
  position: absolute;
  /* top: 20px;
  left: 20px; */
  top: 0px;
  left: 0px;
  /* transform: translate(-0%, -100%); */
}