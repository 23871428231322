html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.channel {
  position: absolute;
  z-index: 1;
  top: 50px;
  right: 70px;
  font: 5.0rem Inconsolata, monospace;
  font-weight: 600;
  color: greenyellow;
  filter: blur(1.6px);
  /* text-shadow: 0 0 5px #ffffff; */
  /* -webkit-text-stroke: 1px white; */
}

.fabLink {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-3000px, 0, 0);
  }
}

// html {
//   height: 100%;
// }

// body {
//   margin: 0;
//   min-height: 100%;
//   background-color: #6eace6;
//   background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/62127/bg-pdx-mountains.svg");
//   background-position: bottom;
//   background-repeat: repeat-x;
//   background-size: auto 180px;
// }

.wrapper,
.far,
.mid,
.near {
  min-height: 1000px;
  // position: absolute;
  position: fixed;
  bottom: 0;
  z-index: -1;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.far,
.mid,
.near {
  width: 6000px;
  height: 400px;
  background-repeat: repeat-x;
  background-position: left bottom;
  animation: slide linear infinite;
}

.far {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/62127/bg-pdx-forest-far.svg");
  animation-duration: 120s;
  background-size: 1000px 180px;
}

.mid {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/62127/bg-pdx-forest-mid.svg");
  animation-duration: 90s;
  background-size: 1000px 140px;
}

.near {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/62127/bg-pdx-forest-near.svg");
  background-size: 800px 100px;
  height: 158px;
  animation-duration: 60s;
}

// .candebody {
//   font-family: aquilinetworegular, papyrus, serif;
// }

.boxshadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}